import IconClose from '@/components/Icon/close'
import IconOpen from '@/components/Icon/open'
import { Accordion, AccordionItem, Selection } from '@nextui-org/react'
import { useState } from 'react'

export default function HelpExchange() {
	const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set(['1']))

	return (
		<Accordion selectedKeys={selectedKeys} onSelectionChange={setSelectedKeys}>
			<AccordionItem
				indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
				startContent={<div className="text-3xl">1</div>}
				key="1"
				classNames={{
					title: 'text-2xl',
				}}
				aria-label="Accordion 1"
				title="What is the difference between a fixed and a float rates?"
			>
				<div className="tail-no-default flex flex-col gap-3 pl-6 text-sm">
					<strong>Fixed rate</strong>
					<p>Opting for a fixed rate, you get the price you see at the point of initiating a transaction.</p>
					<ul>
						<li>Pay just 1% + network fee.</li>
						<li>Rates freeze for 10 minutes.</li>
						<li>
							If the market rate changes by more than 1.2% before the appearance of your transaction on the blockchain network,
							you will be asked to make a refund or continue exchanging at the market rate.
						</li>
					</ul>
					<p>
						<em>
							Attention! Your transaction must be received within 10 minutes and the amount must exactly match the amount of the
							order. Otherwise, you will be prompted to make a refund or continue the exchange at the market rate.
						</em>
					</p>
					<strong>Float rate</strong>
					<p>
						The exchange rate is finally set when your transaction receives the necessary number of blockchain network
						confirmations. If the market goes up, you will get more cryptocurrency, if down - less. All fair.
					</p>
					<ul>
						<li>Pay just 0.5% + network fee.</li>
						<li>
							The exchange will be made at the market rate, which is finally set within 10 minutes after your transaction receives
							the required number of confirmations in the blockchain network.
						</li>
					</ul>
					<p>
						<strong>Example:</strong> you are exchanging 1 BTC for 28.37362388 ETH. With a fixed rate, you will receive exactly
						28.37362388 ETH. With a float exchange rate, the amount of ETH can change both up and down. It depends on changes in
						the market rate.
					</p>
				</div>
			</AccordionItem>
			<AccordionItem
				startContent={<div className="text-3xl">2</div>}
				classNames={{
					title: 'text-2xl',
				}}
				key="2"
				indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
				aria-label="Accordion 2"
				title="What are Fast Coin fees?"
			>
				<div className="tail-no-default flex flex-col gap-3 pl-6 text-sm">
					<p>You can choose from two exchange strategies:</p>
					<ul>
						<li>
							<strong>Fixed rate:</strong> Fast Coin fee 1% + network fee. The exchange rate is fixed at the time of order
							creation.
						</li>
						<li>
							<strong>Float rate:</strong> Fast Coin fee 0.5% + network fee. The exchange rate is set only after your transaction
							arrives and is confirmed by the network. The order amount may change from the moment the order was created due to
							changes in the market rate, both up and down.
						</li>
					</ul>
					<p>
						The network fee is a payment to miners for processing a transaction, its amount is individual for each blockchain.
					</p>
				</div>
			</AccordionItem>
			<AccordionItem
				startContent={<div className="text-3xl">3</div>}
				classNames={{
					title: 'text-2xl',
				}}
				key="3"
				indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
				aria-label="Accordion 2"
				title="How long does the exchange take?"
			>
				<div className="tail-no-default flex flex-col gap-3 pl-6 text-sm">
					<p>
						As soon as your transaction arrives at our address and receives the required number of network confirmations, the
						exchange is automatically performed and a transaction is sent to your address. The waiting time for transaction
						confirmation depends on several factors: the network fee you specified when sending it and the speed of the blockchain
						network.
					</p>
				</div>
			</AccordionItem>
			{/* <AccordionItem
				startContent={<div className="text-3xl">4</div>}
				classNames={{
					title: 'text-2xl',
				}}
				key="4"
				indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
				aria-label="Accordion 2"
				title="How long does the exchange take?"
			>
				<div className="tail-no-default flex flex-col gap-3 pl-6 text-sm">
					<p>
						If you send us a transaction with a low network fee, you risk spending a lot of time waiting for the order to be
						completed. The order will be executed automatically only after your transaction receives the required number of
						network confirmations. Due to the low fees, the transaction may have poor priority and not be confirmed by the network
						anytime soon.
					</p>
				</div>
			</AccordionItem> */}
			<AccordionItem
				startContent={<div className="text-3xl">4</div>}
				classNames={{
					title: 'text-2xl',
				}}
				key="4"
				indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
				aria-label="Accordion 2"
				title="What can I do to speed up the transaction?"
			>
				<div className="tail-no-default flex flex-col gap-3 pl-6 text-sm">
					<p>
						The average amount of the network fee is individual for each blockchain, and depending on the current load, it may
						change. The more you set the network fee, the higher the priority of the transaction and the faster it will be
						processed. Usually, the optimal network fee is automatically calculated by your wallet.
					</p>
					<p>
						If you have sent us a transaction on the Bitcoin or Ethereum network and are waiting for a network confirmation for a
						long time, you can increase the priority of your transaction on the network using the RBF feature.
					</p>
				</div>
			</AccordionItem>
			<AccordionItem
				startContent={<div className="text-3xl">5</div>}
				classNames={{
					title: 'text-2xl',
				}}
				key="5"
				indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
				aria-label="Accordion 2"
				title="Why does sending a transaction take so long?"
			>
				<div className="tail-no-default flex flex-col gap-3 pl-6 text-sm">
					<p>
						The speed of transaction processing depends on the bandwidth of the blockchain and the set fee of the network when it
						is sent. We automatically send transactions with the current network fee. Sometimes in the blockchain network there
						are increased loads beyond our control and untimely release of new blocks.
					</p>
				</div>
			</AccordionItem>
			<AccordionItem
				startContent={<div className="text-3xl">6</div>}
				classNames={{
					title: 'text-2xl',
				}}
				key="6"
				indicator={({ isOpen }) => (!isOpen ? <IconOpen /> : <IconClose />)}
				aria-label="Accordion 2"
				title="Why is my wallet address recognized as invalid?"
			>
				<div className="tail-no-default flex flex-col gap-3 pl-6 text-sm">
					<p>
						Carefully check the network of the address you are trying to enter when creating the order, and the network of the
						currency you have selected in the "Receive" field. If your address is determined to be invalid, then your address
						network does not match the network of the selected currency.
					</p>
				</div>
			</AccordionItem>
		</Accordion>
	)
}
